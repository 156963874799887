import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { mapState, mapActions } from "vuex";
import { ConfigCheck, CheckerApi } from '@/api';
import { columns } from '../../appraiserSettlement/config';
import { TreeSelect } from "ant-design-vue";
var SHOW_ALL = TreeSelect.SHOW_ALL;
export default {
  data: function data() {
    return {
      categorywithBrand: undefined,
      SHOW_ALL: SHOW_ALL,
      checkrangeids: [],
      errlineIndex: 0,
      categoryname: '',
      appstatus: false,
      previewImage: '',
      previewVisible: false,
      headers: ['鉴定点名称', '鉴定icon', '鉴定点示例图', '拍照引导图', '鉴定点描述'],
      probodys: [],
      defaultProbodys: {
        items: [{
          type: 'input',
          placeholder: '请输入名称',
          desc: '',
          id: ''
        }, {
          type: 'upload',
          memorySize: '50kb',
          imgProportion: [60, 60],
          imgurl: '',
          id: ''
        }, {
          type: 'upload',
          memorySize: '200kb',
          imgProportion: [1000, 1000],
          imgurl: '',
          id: ''
        }, {
          type: 'upload',
          memorySize: '100kb',
          imgProportion: [500, 500],
          imgurl: '',
          id: ''
        }, {
          type: 'input',
          placeholder: '请输入描述',
          desc: '',
          id: ''
        }]
      }
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions("base", ["getCategoryBrand"])), {}, {
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    fillterFUN: function fillterFUN(searchVal, treeNode) {
      return treeNode.data.props.title.toLowerCase().includes(searchVal.toLowerCase());
    },
    handleConfUpdate: function handleConfUpdate(params) {
      var _this = this;
      ConfigCheck.confUpdate(params).then(function (res) {
        _this.toast("操作成功", "success");
        _this.$router.push({
          path: "/configCheck/index",
          query: {
            isUpdateData: 1
          }
        });
      });
    },
    getConfDetail: function getConfDetail() {
      var _this2 = this;
      ConfigCheck.confDetail({
        id: this.editid
      }).then(function (res) {
        _this2.categorywithBrand = res.relationid;
        _this2.categoryname = res.categoryname;
        _this2.appstatus = res.status == 1 ? false : true;
        var resprobodys = [];
        resprobodys = res.items.map(function (item, index) {
          return [{
            type: 'input',
            placeholder: '请输入名称（最多30位）',
            desc: item.title,
            id: item.id
          }, {
            type: 'upload',
            memorySize: '50kb',
            imgProportion: [60, 60],
            imgurl: item.imgurl,
            id: item.id
          }, {
            type: 'upload',
            memorySize: '200kb',
            imgProportion: [1000, 1000],
            imgurl: item.exampleurl,
            id: item.id
          }, {
            type: 'upload',
            memorySize: '100kb',
            imgProportion: [500, 500],
            imgurl: item.dottedlineurl,
            id: item.id
          }, {
            type: 'input',
            placeholder: '请输入描述（最多100位）',
            desc: item.common,
            id: item.id
          }];
        });
        if (!resprobodys.length) {
          return _this2.probodys = [JSON.parse(JSON.stringify(_this2.defaultProbodys))];
        }
        _this2.probodys = resprobodys.map(function (it) {
          return {
            items: it
          };
        });
      });
    },
    handleSubmit: function handleSubmit(val) {
      if (!val) {
        return this.$router.push({
          path: "/configCheck/index",
          query: {
            isUpdateData: 0
          }
        });
      }
      // 拼接items 参数
      if (!this.isItemComplete()) {
        return this.toast("\u8BF7\u5148\u5B8C\u6210 \u7B2C".concat(this.errlineIndex + 1, "\u9879 \u6240\u6709\u8F93\u5165\u9879\u6216\u8005\u56FE\u7247\u4E0A\u4F20"), "error");
      }
      if (this.confType == 1 && !this.categorywithBrand) {
        return this.toast("\u8BF7\u9009\u62E9\u6620\u5C04\u9879", "error");
      }
      var items = [];
      for (var i = 0; i < this.probodys.length; i++) {
        var it = this.probodys[i].items;
        items.push({
          id: it[0].id,
          imgurl: it[1].imgurl,
          title: it[0].desc,
          exampleurl: it[2].imgurl,
          dottedlineurl: it[3].imgurl,
          common: it[4].desc
        });
      }
      var params = {
        ids: this.selectids,
        status: this.appstatus == true ? 0 : 1,
        ishot: '',
        items: items,
        relationid: this.categorywithBrand
      };
      console.log('params', params);
      // return false
      this.handleConfUpdate(params);
    },
    onChange: function onChange(e) {},
    handleCancel: function handleCancel() {
      this.previewVisible = false;
    },
    previewImg: function previewImg(data, index) {
      this.previewImage = data.imgurl;
      this.previewVisible = true;
    },
    isItemComplete: function isItemComplete() {
      console.log(111);
      var isComplete = true;
      this.errlineIndex = 0;
      console.log('probodys', this.probodys);
      for (var i = 0; i < this.probodys.length; i++) {
        for (var j = 0; j < this.probodys[i].items.length; j++) {
          // 图片未上传
          if (this.probodys[i].items[j].type == 'upload' && !this.probodys[i].items[j].imgurl) {
            this.errlineIndex = i;
            return isComplete = false;
          }
          // 输入项未完成
          if (this.probodys[i].items[j].desc == '') {
            this.errlineIndex = i;
            return isComplete = false;
          }
        }
      }
      return isComplete = true;
    },
    handleAdd: function handleAdd() {
      if (!this.isItemComplete()) {
        return this.toast("\u8BF7\u5148\u5B8C\u6210 \u7B2C".concat(this.errlineIndex + 1, "\u9879 \u6240\u6709\u8F93\u5165\u9879\u6216\u8005\u56FE\u7247\u4E0A\u4F20"), "error");
      }
      this.probodys.push(JSON.parse(JSON.stringify(this.defaultProbodys)));
    },
    handleDel: function handleDel(item, index) {
      this.probodys = this.probodys.filter(function (it, idx) {
        return index != idx;
      });
    }
  }),
  computed: _objectSpread(_objectSpread({}, mapState('base', ['categoryBrandList'])), {}, {
    editid: function editid() {
      return this.$route.query.id;
    },
    // 0: 人工鉴定 1:AI鉴定
    confType: function confType() {
      return this.$route.query.confType;
    },
    // 1:编辑 2:批量
    editType: function editType() {
      return this.$route.query.editType;
    },
    categorynameArr: function categorynameArr() {
      var categorynameArr = this.$route.query.categorynameArr;
      return categorynameArr.map(function (it, idx) {
        return it;
      });
    },
    selectids: function selectids() {
      return this.editType == 1 ? [this.editid] : this.$route.query.selectids;
    },
    replaceTreeData: function replaceTreeData() {
      if (this.checkrangeids.length) {
        var data = JSON.parse(JSON.stringify(this.checkrangeids));
        var res = JSON.stringify(data);
        res = res.replace(/name/g, 'title');
        res = res.replace(/id/g, 'value');
        res = res.replace(/items/g, 'children');
        res = res.replace(/brands/g, 'children');
        console.log('res', JSON.parse(res));
        return JSON.parse(res);
      }
    }
  }),
  created: function created() {
    this.getCategoryBrand();
    if (this.editType == 1) {
      return this.getConfDetail();
    }
    this.probodys = [JSON.parse(JSON.stringify(this.defaultProbodys))];
  }
};