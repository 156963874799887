import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "top_box"
  }, [_c("span", {
    staticClass: "fo_title"
  }, [_vm._v("配置项")]), _vm.editType == 1 ? _c("span", {
    staticStyle: {
      "margin-top": "auto"
    }
  }, [_vm._v(_vm._s(_vm.categoryname || "---"))]) : _vm._e(), _vm.editType == 2 ? _c("div", {
    staticClass: "titles_box"
  }, _vm._l(_vm.categorynameArr, function (item, index) {
    return _c("span", {
      key: "".concat(item, "-").concat(index),
      staticClass: "batch_titles"
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0) : _vm._e()]), _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("span", {
    staticClass: "fo_title"
  }, [_vm._v("应用状态")]), _c("span", [_vm._v("停用")]), _c("a-switch", {
    staticStyle: {
      margin: "0 5px"
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.appstatus,
      callback: function callback($$v) {
        _vm.appstatus = $$v;
      },
      expression: "appstatus"
    }
  }), _c("span", [_vm._v("生效")])], 1), _vm.confType == 1 ? _c("div", {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("span", {
    staticClass: "fo_title"
  }, [_vm._v("映射项")]), _c("a-select", {
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "show-search": "",
      "filter-option": _vm.filterOption,
      fo_title_top: "",
      placeholder: "请选择"
    },
    model: {
      value: _vm.categorywithBrand,
      callback: function callback($$v) {
        _vm.categorywithBrand = $$v;
      },
      expression: "categorywithBrand"
    }
  }, _vm._l(_vm.categoryBrandList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1) : _vm._e(), _c("div", {
    staticClass: "pro_box",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c("span", {
    staticClass: "fo_title fo_title_bottom"
  }, [_vm._v("鉴定点配置")]), _c("div", [_c("div", {
    staticClass: "table_header"
  }, _vm._l(_vm.headers, function (it, hidx) {
    return _c("div", {
      staticClass: "t_item",
      class: [1, 2, 3, 4].includes(hidx) ? "len_t_item" : ""
    }, [_c("span", [_vm._v(_vm._s(it))])]);
  }), 0), _c("div", {
    staticClass: "table_body"
  }, _vm._l(_vm.probodys, function (it, idx) {
    return _c("div", {
      staticClass: "b_item"
    }, [_c("div", {
      staticClass: "b_container"
    }, [_vm._l(it.items, function (item, index) {
      return _c("div", {
        key: item + index,
        staticClass: "b_box",
        class: [1, 2, 3, 4].includes(index) ? "len_t_item" : ""
      }, [item.type == "input" ? _c("div", [_c("a-textarea", {
        staticStyle: {
          width: "142px",
          border: "0",
          "min-height": "184px",
          resize: "none"
        },
        attrs: {
          "max-length": index == 0 ? 30 : 100,
          rows: 8,
          placeholder: item.placeholder
        },
        model: {
          value: item.desc,
          callback: function callback($$v) {
            _vm.$set(item, "desc", $$v);
          },
          expression: "item.desc"
        }
      })], 1) : _vm._e(), item.type == "upload" ? _c("div", [_c("UploadImg", {
        attrs: {
          listType: "picture-card",
          max: 1,
          memorySize: item.memorySize,
          imgProportion: item.imgProportion
        },
        on: {
          previewImg: function previewImg($event) {
            return _vm.previewImg(item, index);
          }
        },
        model: {
          value: item.imgurl,
          callback: function callback($$v) {
            _vm.$set(item, "imgurl", $$v);
          },
          expression: "item.imgurl"
        }
      })], 1) : _vm._e()]);
    }), idx == _vm.probodys.length - 1 && idx != 0 && _vm.confType == 0 ? _c("span", {
      staticClass: "del",
      on: {
        click: function click($event) {
          return _vm.handleDel(it, idx);
        }
      }
    }, [_vm._v("删除")]) : _vm._e()], 2)]);
  }), 0), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  }), _vm.confType == 0 ? _c("div", {
    staticClass: "add",
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(" + 添加鉴定点 ")]) : _vm._e()], 1)]), _c("div", {
    staticClass: "footer_box"
  }, [_c("a-button", {
    staticStyle: {
      "margin-right": "20px",
      border: "0",
      background: "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleSubmit();
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["configCheckedit:btn:submit"],
      expression: "['configCheckedit:btn:submit']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleSubmit(1);
      }
    }
  }, [_vm._v("确定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };