var searchKeys = [{
  key: "nickname",
  label: "鉴定师",
  placeholder: "名称/邮箱",
  required: false,
  rules: [],
  input: true
}, {
  key: "brandid",
  label: "品牌",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: 'categoryid',
  label: '类目',
  placeholder: "请选择",
  cascader: true
}, {
  key: "status",
  label: "状态",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };